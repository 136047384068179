import React, { useState, useRef, useEffect } from "react";

// Services
import AuthService from "../../../services/auth.service.js";
import assessmentService from "../../../services/assessment.service.js";


// Components
import { Card, CardBody, CardHeader, Col, Row} from "reactstrap";
import {  Modal } from "react-bootstrap";
import { Collapse,List,Typography,Radio} from 'antd';
import styles from "../style/AssessmentStyle/StartAssessment.module.css";
// Assets
import Watch from "../../../assets/img/icons/watch.png";
// import right from "../../../assets/img/icons/right.png";

/*
* Time Based assessment view
*
* This view is responsible for displaying assessment questions & recording the answers
*
* TODO: Combine non time based & time based to a single component
*
* */
const QuizView = (props) => {
    var currentUser = AuthService.getCurrentUser();
    if (currentUser.roleName === "super-admin") {
        props.history.push("/dashboard/default");
        window.location.reload();
    }
    if (currentUser == null || currentUser == undefined) {
        props.history.push("/");
        window.location.reload();
    }

    if (props.location.state == null || props.location.state == undefined) {
        props.history.push({
            pathname: "/Assessments",
        });
        window.location.reload();
    }
    if (props.history.action == "POP") {
        var isKeyPresent = JSON.parse(sessionStorage.getItem("AssessmentQuizKey"));
        if (isKeyPresent == null) {
            props.history.push({
                pathname: "/Assessments",
            });
            window.location.reload();
        }
    }
    const titleRef = useRef();
    //fetching questionObject from props as passed from assessment home page
    var questions = props.location.state.questionsList;
    let AssessmentsDetails = props.location.state.AssessmentsDetails;
    console.log(props.location.state);

    //declaring other const for quiz related functionality
    const [questionCounterIsBehaviour, setQuestionCounterIsBehaviour] = useState(0);
    const [questionCounterIsntBehaviour, setQuestionCounterIsntBehaviour] = useState(0);
    const [Details, setDetails] = useState(
        (AssessmentsDetails.AssessmentDescriptionDetailList!=null)? AssessmentsDetails.AssessmentDescriptionDetailList.filter(x => x.TypeId==3 || x.TypeId ==4):''
    );
    

    const [questionsIsBehaviour,setQuestionsIsBehaviour] = useState([]);
    const [questionsIsntBehaviour,setQuestionsIsntBehaviour] = useState([]);
    const [items,setItems] = useState([]);
    // var questionsIsBehaviour = []
    const TitleList = questions;
    // const [iconlist,setIconlist] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    //declaring local variables for side bar qestion highlighting
    var count = 0;
    var topicNavCount = 0;
    useEffect(() => {
        if(questions){let a = [];
        let b = [];
        let count = 0;
        let count2 = 0;
        let datas = [];
        TitleList.TitleList.forEach(item => {
            if (item.Behaviour === "True") {
                a.push(item);
            } else if (item.Behaviour === "False") {
                b.push(item);
            }
        });
    
        // 更新状态之前完成所有计算
        setQuestionsIsBehaviour(a);
        setQuestionsIsntBehaviour(b);
    
        // 提取公共函数以避免重复代码
        const generateItemData = (item, index) => {
            let Listdata = item.QuestionList.map(aitem => aitem.Name);
            return {
                key: index,
                label: (
                    <div className="labelItem" id={`label${index}`} style={{display: 'flex', justifyContent: 'start'}}>
                        <div style={{width: '20px', display: 'inline-block', height: '20px', background: '#92929D', borderRadius: '50%', marginRight: '6px'}}></div>
                        {item.TitleName}
                    </div>
                ),
                children: (
                    <List
                        dataSource={Listdata}
                        size="small"
                        renderItem={(item) => (
                            <List.Item style={{display: 'flex', justifyContent: 'start', paddingLeft: '25px', color: '#92929D',border:'none',padding:'4px 16px'}}>
                                <div style={{width: '20px', display: 'inline-block', height: '20px', background: '#92929D', borderRadius: '50%', marginRight: '6px'}}></div>
                                {item}
                            </List.Item>
                        )}
                    />
                )
            };
        };
    
        // 遍历a和b，使用公共函数生成数据
        a.forEach((item, index) => {
            count += item.QuestionList.length;
            datas.push(generateItemData(item, index));
        });
        b.forEach((item, index) => {
            count2 += item.QuestionList.length;
            datas.push(generateItemData(item, a.length + index));
        });
        sessionStorage.removeItem('isOwnCaseStudy');
        setItems(datas);
        setQuestionCounterIsBehaviour(count);
        setQuestionCounterIsntBehaviour(count2);
        if(AssessmentsDetails.AssessmentDescriptionDetailList.length > 0){
            AssessmentsDetails.AssessmentDescriptionDetailList[1].AhriDescription = AssessmentsDetails.AssessmentDescriptionDetailList[1].AhriDescription.replace(/{QuestionNumber}/g, count+count2).replace(/{sectionNumber}/g, TitleList.TitleList.length)
        }}
    }, []);
    const onChange = (e) => {
        const elements = document.getElementsByClassName('labelItem');
        // 将elements转换为数组，以便使用forEach
        Array.from(elements).forEach(element => {
            console.log(element.style.fontWeight);
            // 修改样式
            element.style.fontWeight = '';

        });
        e.map((item,index) => {
            document.getElementById('label'+item).style.fontWeight = 'bold';
        } )
    }

    
    const formatToHoursAndMinutes = (secondsVal) => {
        
            var sec_num = parseInt(secondsVal, 10);
            var hours = Math.floor(sec_num / 3600);
            var minutes = Math.floor(sec_num / 60) % 60;
            var seconds = sec_num % 60;

            return [hours, minutes, seconds]
                .map((v) => (v < 10 ? "0" + v : v))
                .filter((v, i) => v !== "00" || i > 0)
                .join(":");
        
        
    };
    const Start = () => {
        var currentUser = AuthService.getCurrentUser();
        var details = assessmentService.AddEntryInUserTestTable(currentUser.id, props.location.state.assessmentId, props.location.state.creditId).then((response) => {
            return response;
        })
            .then((data) => {
                if((AssessmentsDetails.AiAssessmentType != 2 && AssessmentsDetails.AiAssessmentType != 3)){
                    props.history.push({
                        pathname: '/AssessmentQuiz',
                        state: { assessmentId: props.location.state.assessmentId, questionsList: props.location.state.questionsList, creditId: props.location.state.creditId,AssessmentsDetails:AssessmentsDetails }
                    })
                }else{
                    assessmentService.GetCaseStudyDetailsByAssessmentID(props.location.state.assessmentId,props.location.state.creditId,currentUser.id).then((response) => {
                        if (response !== null) {
                            props.history.push({
                                pathname: '/Assessments/CaseStudyQuiz',
                                state: { AssessmentID: props.location.state.assessmentId,AssessmentsDetails:response,CreditId:props.location.state.creditId,AssessmentsInfo:AssessmentsDetails}
                            })
                        }
                    })
                }
               
            })
            .catch(e => {
                console.log(e);
            })
    }
    return (
        <>
            <Row>
                <div className={(AssessmentsDetails.AiAssessmentType != 2 && AssessmentsDetails.AiAssessmentType != 3)?"col-lg-8 col-md-8":"col-lg-12 col-md-12"} style={{color:"#131523"}}>
                    <Card className="my-card assessment-wrapper-home">
                        <CardBody className={`pb-0 ${styles.introduce_item}`}>
                            <span dangerouslySetInnerHTML={{__html:AssessmentsDetails.AssessmentDescriptionDetailList[0].AhriDescription}}>
                            </span>
                        </CardBody>
                        <hr className="my-hr" />
                        <CardBody style={{padding: "0 1.25rem"}}>
                            <Row className={styles.introduce_item}>
                                <div className="col-lg-1 col-md-1 d-flex" style={{justifyContent: 'center'}}>
                                    <div className="question-number rounded-circle text-white font-weight-bold h3" style={{flexShrink: '0'}}>
                                       1
                                    </div>
                                </div>
                                <div className="col-lg-11 col-md-11" dangerouslySetInnerHTML={{__html:AssessmentsDetails.AssessmentDescriptionDetailList[1].AhriDescription}}>
                                    {/* <p>This assessment is structured around {TitleList.TitleList.length} key sections underpinned by {(questionCounterIsntBehaviour + questionCounterIsBehaviour)} targeted questions that reflect the core aspects of HR competencies.</p> */}
                                </div>
                            </Row>
                            {(AssessmentsDetails.AiAssessmentType != 2 && AssessmentsDetails.AiAssessmentType != 3) &&<div style={{paddingLeft:"10%",display:'flex'}}>
                                { questionsIsBehaviour.length !== 0 &&
                                    <Row style={{flexDirection: 'column',marginRight: '5%',width:'45%'}}>
                                        <h5 style={{fontWeight:"600",fontSize:"18px",color:'#00995E'}}>BEHAVIOURS - I AM</h5>
                                        <p style={{fontSize:"16px"}}>
                                        This segment consists of {questionCounterIsBehaviour} questions and focuses on behaviours deemed integral to effectively navigating the complexities of HR, driving organisational success, and adapting to ever-changing workplaces.
                                        </p>
                                    </Row>
                                }
                                <Row style={{alignContent: 'flex-start',width: '45%'}}>
                                <h5 style={{fontWeight:"600",fontSize:"18px",color:'#00995E'}}>CAPABILITIES - I DO</h5>
                                    {questionsIsntBehaviour.map((item,index)=>(
                                        <div className="col-lg-12 col-md-12" key={index} style={{fontWeight:"500",fontSize:"16px"}}>{index +1}.&nbsp;{item.TitleName}</div>)
                                    )}
                                </Row>                             
                            </div>   }
                        </CardBody>
                        <hr className="my-hr" />
                        {Details.map((item, index)=>{
                            return (
                                <div key={index}>

                              {(item.AhriDescription!='') &&  
                                <>
                                <CardBody style={{padding: "0 1.25rem"}}>
                                        <Row className={styles.introduce_item}>
                                            <div className="col-lg-1 col-md-1 d-flex" style={{justifyContent: 'center'}}>
                                                <div className="question-number rounded-circle text-white font-weight-bold h3" style={{flexShrink: '0'}}>
                                                {index+2}
                                                </div>
                                            </div>
                                            <div className="col-lg-11 col-md-11" dangerouslySetInnerHTML={{__html:item.AhriDescription}}>
                                            </div>
                                        </Row>
                                    </CardBody>
                                    <hr className="my-hr" />
                                </>}
                                </div>
                            )
                        })}
                       
                        <CardBody style={{padding: "0 1.25rem"}}>
                            <Row className="align-items-top" style={{justifyContent: "center"}}>
                                <div className="mt-5 mb-4">
                                    <button
                                        id="submit-button"
                                        className="quiz-submit-btn btn btn-primary d-block mr-0 ml-auto"
                                       onClick={Start}
                                       style={{backgroundColor:'var(--theme_color1)',borderColor:'var(--theme_color1)'}}
                                    >
                                        START ASSESSMENT
                                    </button>
                                </div>
                            </Row>
                        </CardBody>
                       
                    </Card>
                </div>
                {(AssessmentsDetails.AiAssessmentType != 2 && AssessmentsDetails.AiAssessmentType != 3) && <div className="col-lg-4 col-md-4">
                    <Card
                        className="my-card"
                        style={{
                            maxHeight: "705px",
                        }}
                    >
                        {(questions && questions.DefaultTime !=='0 minutes') ?
                        <>
                        <CardHeader className="pb-0">
                            <Row>
                                <div className="col-lg-12 col-md-12">
                                    <Row>
                                        <Col sm={4} md={4}>
                                            <img
                                                src={Watch}
                                                className="img-responsive mt-2 mx-auto d-block"
                                                width="80"
                                                height="80"
                                            />
                                        </Col>
                                        <Col sm={8} md={8}>
                                            <div>
                                                <p className="mb-0">Your time left</p>
                                                <h3 className="display-4 font-weight-bold text-dark" style={{fontSize:"2.9rem"}}>
                                                    {formatToHoursAndMinutes(props.location.state.questionsList.AssessmentDefaultTime)}
                                                </h3>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Row>
                        </CardHeader>
                        <hr className="my-hr" />
                        </>:<div style={{marginTop: "2rem",width:'100%'}}></div>
                        }
                        

                        <div className="scrollbar1" style={{maxHeight: '35rem',overflow: 'scroll'}}>
                        {( questions &&questions.DefaultTime !=='0 minutes' && questions.DefaultTime !== null )&&
                        <>
                            <CardBody
                                className="py-0"
                                style={{
                                    minHeight: "auto",
                                }}
                            >
                                <Row>
                                    <div className="h1 font-weight-bold text-dark text-center px-2" style={{width:'100%',fontSize:'1.75rem'}}>
                                    THIS IS A <span style={{color:'red'}}>TIMED TASK!</span>
                                    </div>
                                    {/* <p style={{textAlign:'center',fontSize:'0.8rem',padding:"0 5px"}}>
                                        You've got a strict {questions.DefaultTime} timeframe to complete this evaluation. When you click ‘begin assessment’ the countdown begins. <br></br>
                                        With eight sections to cover, try not to spend more than 5 minutes on each - that way, you'll get through all the sections in about 40 minutes. Use the remaining 20 minutes to review your answers and double-check anything you're unsure about.<br></br>
                                        IMPORTANT: If you don't finish within the {questions.DefaultTime} window, you'll be timed out and won't be able to access it again. So, keep an eye on the clock and make sure you submit on time. Best of luck - you've got this!<br></br>
                                    </p> */}
                                </Row>
                            </CardBody>
                            <hr className="my-hr" />
                        </>
                        }
                        <CardBody
                            className="py-0"
                            style={{
                                minHeight: "auto",
                            }}
                        >
                            <Row>
                                <h2 className="h1 font-weight-bold text-dark text-left" style={{paddingLeft:'2rem',textAlign:'left'}}>
                                    Your Progress 
                                </h2>
                            </Row>
                        </CardBody>
                        <hr className="my-hr" style={{marginBottom: '0'}}  />
                            <Collapse items={items} bordered={false} accordion  expandIconPosition="end" onChange={(e)=> onChange(e)} />
                        </div>
                        
                    </Card>
                </div>}
            </Row>
        </>
    );
};

const StartassessmentQuiz = (props) => (
    <>
        <h2>Welcome to the {props.location.state.AssessmentsDetails.AssessmentName}</h2>
        <QuizView {...props} />
    </>
);

export default StartassessmentQuiz;
