
import React, { useEffect, useState } from "react";

// Services
import AuthService from "../../../services/auth.service.js";
import BarChart from "../../../components/chart/BarChart";
import Radar from "../../../components/chart/Radar.js";

// Components
import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
    Col,
    Row,
    Table,
} from "reactstrap";
import { Filter, Settings } from "react-feather";
import { Button } from "react-bootstrap";
import icondownload from "../../../assets/img/icons/download.png";
import userService from "../../../services/user.service";
import {toast} from 'react-toastify';
import assessmentService from "../../../services/assessment.service.js";
import warningIcon from "../../../assets/img/icons/warningIcon.png";
/*
* Assessment Results View
*
* This component should display the results of a particular assessment
*
* TODO: This needs to be worked on
* */
const AssessmentsTypes = (props) => {
    var currentUser = AuthService.getCurrentUser();
    const [showdownloadBtn, setShowdownloadBtn] = useState(false);
    const [downloadURL, setDownloadURL] = useState('');
    const [downloadDIsabled, setDownloadDIsabled] = useState(false);
    if (currentUser == null || currentUser == undefined) {
        props.history.push("/");
        window.location.reload();
    }
    var score = 0;
    var data, dataRadar,questionsList, status, statusColor = null;
    
    
    useEffect(()=>{
        console.log(props.location.state.questionsList);
        assessmentService.GetReportLink(props.location.state.Data.UserId,props.location.state.Data.AssessmentId,props.location.state.Data.CreditId).then(
            (response) => {
                if (response !== null) {
                   if(response.Status == "Success"){
                        setDownloadURL(response.Message);
                        setDownloadDIsabled(false);
                   }else{
                        setDownloadDIsabled(true);
                   }
                }
        });
        userService.GetUserProfiledetailsByUserId(currentUser.id).then(
            (response) => {
                if (response !== null) {
                    if(response.report_access == true){
                        setShowdownloadBtn(true)
                    }
                }
        });
        console.log(props.location.state.Data.LinkedAssessmentId);
        return(()=>{
            window.sessionStorage.removeItem('resultData')
        })
    },[])

    //下载摸板
    const downloadReport = () =>{
        var downloadUrl =  downloadURL;
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.click();
        window.URL.revokeObjectURL(downloadUrl);
    }

    if (props.location.state == null || props.location.state == undefined) {
        score = 0;
    }
    else {
        score = props.location.state.Score;
        questionsList = props.location.state.questionsList;
        const labels = props.location.state.Data.TopicWisePercentage.map(item => item.TopicName);
        const dataPoints = props.location.state.Data.TopicWisePercentage.map(item => item.Percentage);
        dataRadar = {
            labels: labels,
            datasets: [
                {
                    label: "Score Breakdown",
                    backgroundColor: "rgb(0, 153, 94,0.2)",
                    // borderColor: theme.primary,
                    // pointBackgroundColor: theme.primary,
                    pointBorderColor: "#fff",
                    pointHoverBackgroundColor: "#fff",
                    // pointHoverBorderColor: theme.primary,
                    data: dataPoints
                }
            ]
        }

        status = props.location.state.Data.Status;

        switch (status) {
            case 'COMPLETED': statusColor = 'text-success'; break;
            case 'INCOMPLETE': statusColor = 'text-danger'; break;
        }
    }

    return (
        <>
        <h1 style={{marginBottom:'2rem'}}>Assessment Result</h1>
        <Card>
            <CardHeader>
                <CardTitle tag="h1">
                </CardTitle>        
            </CardHeader>

            <CardBody className="pt-0">
                <Row>
                    <Col xs={4} md={4}>
                        <h3 className="text-left text-primary">{props.location.state.Data.AssessmentName}</h3>
                        {/* <p className="h5"><span style={{color:'#92929D',fontWeight:'500'}}> Total Score: </span><strong style={{fontWeight:'700'}}>{score}</strong></p> */}
                        <p className="h5"><span style={{color:'#92929D',fontWeight:'500'}}>Date of completion: </span><strong style={{fontWeight:'700'}}>{props.location.state.Data.EndTime ==''?'pending': props.location.state.Data.EndTime}</strong></p>
                        <p className="text-left font-weight-bold h5"> <span style={{color:'#92929D',fontWeight:'500'}}>Status: </span><strong className={statusColor}>{status}</strong></p>
                        {props.location.state.Data.LinkedAssessmentId == "" &&
                        <>
                        <div style={{display: 'flex', alignItems:'center'}}>
                        {showdownloadBtn && (
                            <div>
                                <button
                                className="shadow-sm mr-1 btn btn-primary disabledcolor"
                                style={{whiteSpace: 'nowrap'}}
                                onClick={downloadReport}
                                disabled={downloadDIsabled}
                            >
                                Download Report
                                <img src={icondownload} width="16" height="16" style={{margin:'0 0 0.2rem 0.5rem'}} alt=""/>
                            </button>
                            </div>
                        )}
                        {showdownloadBtn && downloadDIsabled && <div style={{marginLeft:'0.5rem',display:'inline-block'}}>
                                <img src={warningIcon} width="14" height="14" style={{marginRight:'0.2rem'}}/>
                                <span style={{color:'red'}}> Sit tight. Your report will be ready in just a few minutes.</span>
                            </div>
                        }
                        </div>
                        
                        <p style={{marginTop:'1rem'}}>Your Assessment has been successfully submitted!
                        <br/> Please close the window or start your next assessment.</p>
                        <hr/>
                        <div className="scrollbar2" style={{height:'30rem'}}>
                        {questionsList.TitleList.map((question,i)=>{
                            return(
                                <div key={i}>
                                    <p style={{fontSize:'1.2rem',fontWeight:'600',marginBottom:'0.3rem'}}>{question.TitleName}</p>
                                    <p style={{fontSize:"0.9rem"}}>{question.TitleDescription}</p>
                                </div>
                            )
                        })
                        }
                        </div>
                        </>
                        }
                        
                        {/* <Table>
                            <thead>
                                <tr>
                                    <th>
                                        Dimensions
                                    </th>
                                    <th>
                                        Score Percentage
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.location.state.Data.TopicWisePercentage.map(item => {
                                    return (
                                        <tr>
                                            <td>{item.TopicName}</td>
                                            <td>{item.Percentage}%</td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </Table> */}
                    </Col>
                    <Col xs={8} md={8}>

                       {props.location.state.Data.LinkedAssessmentId == "" && <Radar chartData={dataRadar} />}
                    </Col>
                </Row>
            </CardBody>
        </Card >
        </>
    );
}

const SgaAssessmentsResult = (props) => (
    <>
        <AssessmentsTypes {...props} />
    </>
);



export default SgaAssessmentsResult;
